import React from "react"
import { Link } from "gatsby"

import { rhythm } from "../utils/typography"
import LangToggle from "./langToggle"

interface Props {
  pathname: string
  title: string
  lang?: string
  children?: any
}

const Layout = ({ title, lang = "", children, pathname }: Props) => {
  let header

  header = (
    <h3
      style={{
        fontFamily: `Montserrat, sans-serif`,
        marginTop: 0,
      }}
    >
      <Link
        style={{
          boxShadow: `none`,
          textDecoration: `none`,
          color: `inherit`,
        }}
        to={`${lang}/blog`}
      >
        {title} - Blog
      </Link>
    </h3>
  )
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      {pathname.includes("/blog") && <LangToggle />}
      <header>{header}</header>
      <main>{children}</main>
      <footer>© {new Date().getFullYear()}</footer>
    </div>
  )
}

export default Layout
